import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { CustomProvider } from "rsuite";
import { useCookiesLogin } from "./config/cookies";
import { RouterManager } from "./routes/routerManager";
import ComponentLogin from "./scenes/login";
import ComponentProfile from "./scenes/profile";
import ResetPassword from "./scenes/resetPassword";
import Template from "./template";

function App() {
  const { cookies } = useCookiesLogin();
  const access = cookies.BRAUM_TOKEN?.role ?? "";

  const router = new RouterManager(access);

  let listRoutes = createBrowserRouter([
    {
      path: "/",
      element: <ComponentLogin />,
    },
    {
      path: "resetPassword",
      element: <ResetPassword />, 
    },
    {
      path: "app",
      element: <Template />,
      children: router.manager(),
    },
    {
      path: "app/profile",
      element: <ComponentProfile />
    }
  ]);

  return <CustomProvider theme="dark"><RouterProvider router={listRoutes} /></CustomProvider>;
}

export default App;
