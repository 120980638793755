/* eslint-disable array-callback-return */
import React from "react";
import RedSimulation from "../redsimulation";

const Red25Tri = () => {
  // props
  let uri = 'redtri/';
  let type = "RED25 - Tri";
  let title = "Trimestral";

  return (
    <RedSimulation title={title} simulationUri={uri} type_simulation={type}/>
  );
};

export default Red25Tri;
