import { useTheme } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../theme";

const PieChart = ({ isCustomLineColors = false, isDashboard = false, data = [] }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const amountAprov = data[0].map(p => parseFloat(p.fields.amount_total).toFixed(2));
  const amountRej = data[1].map(p => parseFloat(p.fields.amount_total).toFixed(2));
  const amountOpen = data[2].map(p => parseFloat(p.fields.amount_total).toFixed(2));
  const amountFin = data[3].map(p => parseFloat(p.fields.amount_total).toFixed(2));
  
  amountAprov.sort((a, b) =>  a-b);
  amountRej.sort((a, b) =>  a-b);
  amountOpen.sort((a, b) =>  a-b);
  amountFin.sort((a, b) =>  a-b);
  
  const coordAprov = amountAprov.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue)
  },0);

  const coordRej = amountRej.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue)
  },0);

  const coordOpen = amountOpen.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue)
  },0);

  const coordFin = amountFin.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue)
  },0);

  const dataset = [
    {
      id: "Aprovadas",
      color: "#83d84d",
      label: "Aprovadas",
      value: coordAprov,
    },
    {
      id: "Rejeitadas",
      color: "#ff5050",
      label: "Rejeitadas",
      value: coordRej,
    },
    {
      id: "Em aberto",
      color: "#fff28b",
      label: "Em aberto",
      value: coordOpen,
    },
    {
      id: "Finalizadas",
      color: "#36f357",
      label: "Finalizadas",
      value: coordFin,
    },
  ];

  return (
    <ResponsivePie
      data={dataset}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
      }}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={colors.grey[100]}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      enableArcLabels={false}
      arcLabelsRadiusOffset={0.4}
      arcLabelsSkipAngle={7}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      colors={["#83d84d", "#f44", "#fff28b", "#ccc"]}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
};

export default PieChart;
