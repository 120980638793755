import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router";

export const FormSuccessBussines = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const navigateToDashboard = () => navigate("/app");

  return (
    <Box display="grid" sx={{ m: "15px 0 5px 20px" }}>
      <Typography variant="h2" color="secondary">
        Revenda cadastrada com Sucesso!
      </Typography>
      <Button
        sx={{
          width: 200,
          marginY: 5,
        }}
        type="submit"
        color="inherit"
        variant="outlined"
        onClick={() => navigateToDashboard()}
      >
        Voltar ao dashboard
      </Button>
    </Box>
  );
};

export const FormSuccessClient = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const navigateToDashboard = () => navigate("/app");

  return (
    <Box display="grid" sx={{ m: "15px 0 5px 20px" }}>
      <Typography variant="h2" color="secondary">
        Cliente cadastrado com sucesso!
      </Typography>
      <Button
        sx={{
          width: 200,
          marginY: 5,
        }}
        type="submit"
        color="inherit"
        variant="outlined"
        onClick={() => navigateToDashboard()}
      >
        Voltar ao dashboard
      </Button>
    </Box>
  );
};


export const FormSuccessUpdateProposalStatus = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const navigateToDashboard = () => navigate("/app");

  return (
    <Box display="grid" sx={{ m: "15px 0 5px 20px" }}>
      <Typography variant="h2" color={colors.greenAccent[600]}>
        Proposta atualizada com sucesso!
      </Typography>
      <Button
        sx={{
          width: 200,
          marginY: 5,
        }}
        type="submit"
        color="inherit"
        variant="outlined"
        onClick={() => navigateToDashboard()}
      >
        Voltar ao dashboard
      </Button>
    </Box>
  );
};