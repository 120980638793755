/* eslint-disable array-callback-return */
import { Backdrop, Box, Button, CircularProgress, TextField } from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import * as yup from "yup";
import { greenColor } from "../../config/colors";
import { useCookiesLogin } from "../../config/cookies";
import { HOST_SINGED } from "../../config/hosts";

const RegisterBusiness = () => {
  const { cookies } = useCookiesLogin();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    nome: "",
    razaoSocial: "",
    cnpj: "",
    telefone: "",
    email: "",
    cnaePrincipal: "",
  };

  const validationSchema = yup.object().shape({
    nome: yup.string().required("Nome é obrigatório"),
    razaoSocial: yup.string().required("Razão Social é obrigatória"),
    cnpj: yup.string().required("CNPJ é obrigatório").min(14, "CNPJ inválido"),
    telefone: yup.string().required("Telefone é obrigatório"),
    email: yup.string().email("Email inválido").required("Email é obrigatório"),
    cnaePrincipal: yup.string().required("CNAE Principal é obrigatório"),
  });

  const handleSubmitForm = async (values, { setSubmitting }) => {
    setIsLoading(true);
    setSubmitting(true);

    const requestBody = {
      nome: values.nome,
      razaoSocial: values.razaoSocial,
      cnpj: values.cnpj,
      telefone: values.telefone,
      email: values.email,
      cnaePrincipal: values.cnaePrincipal,
      client_id: cookies.BRAUM_TOKEN.id,
    };
 
    try {
      const response = await fetch(`${HOST_SINGED}/business/register/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success("Negócio registrado com sucesso!", { theme: "dark" });
      } else {
        toast.error("Erro ao registrar o negócio. Tente novamente.", { theme: "dark" });
      }
    } catch (error) {
      toast.error("Erro de rede ao registrar o negócio.", { theme: "dark" });
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Box p={5}>
      <Box mt={1}>
        <ToastContainer />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmitForm}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                <TextField
                  fullWidth
                  variant="filled"
                  label="Nome"
                  color="secondary"
                  value={values.nome}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="nome"
                  error={!!touched.nome && !!errors.nome}
                  helperText={touched.nome && errors.nome}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  label="Razão Social"
                  color="secondary"
                  value={values.razaoSocial}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="razaoSocial"
                  error={!!touched.razaoSocial && !!errors.razaoSocial}
                  helperText={touched.razaoSocial && errors.razaoSocial}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  label="CNPJ"
                  color="secondary"
                  value={values.cnpj}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="cnpj"
                  error={!!touched.cnpj && !!errors.cnpj}
                  helperText={touched.cnpj && errors.cnpj}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  label="Telefone"
                  color="secondary"
                  value={values.telefone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="telefone"
                  error={!!touched.telefone && !!errors.telefone}
                  helperText={touched.telefone && errors.telefone}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  label="Email"
                  color="secondary"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  label="CNAE Principal"
                  color="secondary"
                  value={values.cnaePrincipal}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="cnaePrincipal"
                  error={!!touched.cnaePrincipal && !!errors.cnaePrincipal}
                  helperText={touched.cnaePrincipal && errors.cnaePrincipal}
                  sx={{ gridColumn: "span 2" }}
                />

                <Box sx={{ gridColumn: "span 2" }}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={isLoading || !values.nome || !!Object.keys(errors).length}
                  >
                    Cadastrar Revenda
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>

        <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
          <CircularProgress color="inherit" sx={{ color: greenColor }} />
        </Backdrop>
      </Box>
    </Box>
  );
};

export default RegisterBusiness;
