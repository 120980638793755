import { Backdrop, Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Header from "../../components/Header";
import { greenColor } from "../../config/colors";
import { useCookiesLogin } from "../../config/cookies";
import { HOST_SINGED } from "../../config/hosts";
import { tokens } from "../../theme";

const DashboardUser = () => {
  const { cookies } = useCookiesLogin();
  const response = cookies.BRAUM_TOKEN || {};
  const role = response.role;
  const email = response.email;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [proposals, setProposals] = useState([]);
  const [approvedProposals, setApprovedProposals] = useState([]);
  const [rejectedProposals, setRejectedProposals] = useState([]);
  const [openProposals, setOpenProposals] = useState([]);
  const [valueApprovedProposals, setValueApprovedProposals] = useState([]);
  const [valueRejectedProposals, setValueRejectedProposals] = useState([]);
  const [valueOpenProposals, setValueOpenProposals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
    const navigateToPrposals = () => navigate("/app/proposals/user");
  
  function handleStatusProposals(data) {
    // eslint-disable-next-line no-unused-expressions
    const aprPro = data.filter(proposal => proposal.fields.status === "APPROVED"); 
    const rejPro = data.filter(proposal => proposal.fields.status === "REJECTED"); 
    const opnPro = data.filter(proposal => proposal.fields.status === "CREATED"); 

    setApprovedProposals(aprPro);
    setRejectedProposals(rejPro);
    setOpenProposals(opnPro);
  }

  function handleTotalValueProposals() {
    const valueAprPro = approvedProposals.reduce((accumulator, p) => {
      return accumulator + parseFloat(p.fields.amount_total);
    }, 0);
    
    const valueRejPro = rejectedProposals.reduce((accumulator, p) => {
      return accumulator + parseFloat(p.fields.amount_total);
    }, 0);

    const valueOpnPro = openProposals.reduce((accumulator, p) => {
        return accumulator + parseFloat(p.fields.amount_total);
    }, 0);

    setValueApprovedProposals(valueAprPro);
    setValueRejectedProposals(valueRejPro);
    setValueOpenProposals(valueOpnPro);
  }

  async function getClientProposalData() {
    setIsLoading(true);
    await fetch(`${HOST_SINGED}/reduto/proposal/client`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      body: JSON.stringify({
        email: email
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res;
        }
      })
      .then((response) => response.json())
      .then((data) => {
        handleStatusProposals(data);
        handleTotalValueProposals();
        setProposals(data);
        setIsLoading(false);
      });
  }
  useEffect(() => {
      getClientProposalData();
  }, [valueApprovedProposals, valueRejectedProposals, valueOpenProposals]);

  useEffect(() => {
    document.title = 'Home';
  }, []);


  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={"Olá, " + response.name + "!"} subtitle="Aqui você pode ter acesso às propostas disponíveis para você" />
     </Box>

     <Box>
        <Box>
           <Typography
            variant="h2"
            fontWeight="600"
            color={colors.grey[100]}
           >
            Suas propostas
           </Typography>
        </Box>

        <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
        >
            <Card sx={{p: 5}}>
            <CardContent>
                <Typography variant="h3" color={colors.greenAccent[400]} gutterBottom>
                    Em aberto
                </Typography>
                <Typography sx={{mb: 5}} variant="h4" component="div">
                    Você possui {openProposals.length} proposta(s) em aberto
                </Typography>
                <Typography sx={{mb: 3}} variant="h3" component="div" color={colors.greenAccent[400]}>
                  {parseFloat(valueOpenProposals).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2
                  })}
                </Typography>

                <Button 
                    color="secondary" 
                    variant="contained"
                    onClick={navigateToPrposals}    
                >
                    Saiba mais
                </Button>
            </CardContent>
            </Card>

            <Card sx={{p: 5}}>
            <CardContent>
                <Typography variant="h3" color={colors.greenAccent[400]} gutterBottom>
                    Aprovadas
                </Typography>
                <Typography sx={{mb: 5}} variant="h4" component="div">
                    Você possui {approvedProposals.length} proposta(s) aprovada(s)
                </Typography>
                <Typography sx={{mb: 3}} variant="h3" component="div" color={colors.greenAccent[400]}>
                  {parseFloat(valueApprovedProposals).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2
                  })}
                </Typography>

                <Button 
                    color="secondary" 
                    variant="contained"
                    onClick={navigateToPrposals}    
                >
                    Saiba mais
                </Button>
            </CardContent>
            </Card>

            <Card sx={{p: 5}}>
            <CardContent>
                <Typography variant="h3" color={colors.greenAccent[400]} gutterBottom>
                    Rejeitadas
                </Typography>
                <Typography sx={{mb: 5}} variant="h4" component="div">
                Você possui {rejectedProposals.length} proposta(s) rejeitada(s)
                </Typography>
                <Typography sx={{mb: 3}} variant="h3" component="div" color={colors.greenAccent[400]}>
                  {parseFloat(valueRejectedProposals).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2
                  })}
                </Typography>

                <Button 
                    color="secondary" 
                    variant="contained"
                    onClick={navigateToPrposals}    
                >
                    Saiba mais
                </Button>
            </CardContent>
            </Card>
        </Box>


     </Box>
     <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" sx={{ color: greenColor }} />
      </Backdrop>
    </Box>
  );
};

export default DashboardUser;
