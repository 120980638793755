export const protocolMessages = {
    200: "Acesso autorizado com sucesso.",
    400: "Informações inválidas.",
    403: "Acesso não autorizado.",
    401: "Acesso proibido.",
    404: "Nenhum resultado foi encontrado.",
    500: "Erro interno no servidor.",
    501: "Não implementado.",
    502: "Bad Gateway.",
    503: "Serviço indisponível.",
    504: "Tempo limite do Gateway.",
}