// reducers.js
const initialState = {
  id: null,
  username: null,
  name: null,
  email: null,
  password: null,
  refresh_token: null,
  phone: null,
  company: null,
  isFirstAccess: null,
  role: null,
  createdAt: null,
  updatedAt: null,
  token: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      const {
        id,
        username,
        name,
        email,
        password,
        refresh_token,
        phone,
        company,
        isFirstAccess,
        role,
        createdAt,
        updatedAt,
        token,
      } = action.payload;
      return {
        id: id,
        username: username,
        name: name,
        email: email,
        password: password,
        refresh_token: refresh_token,
        phone: phone,
        company: company,
        isFirstAccess: isFirstAccess,
        role: role,
        createdAt: createdAt,
        updatedAt: updatedAt,
        token: token,
      };

    case "LOGOUT":
      return initialState;

    default:
      return initialState;
  }
};

export default authReducer;
