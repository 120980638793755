/* eslint-disable array-callback-return */
import React from "react";
import RedSimulation from "../redsimulation";

const Red25 = () => {
  // props
  let uri = 'red25/';
  let type = "RED25 - Mes";
  let title = "Mensal";

  return (
    <RedSimulation title={title} simulationUri={uri} type_simulation={type} />
  );
};

export default Red25;
