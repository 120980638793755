import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Topbar from "../scenes/global/Topbar";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../theme";
import { useCookiesLogin } from "../config/cookies";
import { useEffect } from "react";
import SidebarManager from "./helpers/SidebarManager";

function Template() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { cookies } = useCookiesLogin();
  const navigate = useNavigate();

  // Verificando se tem token, caso não tenha será direcionado para a home:
  useEffect(() => {
    if (cookies.BRAUM_TOKEN === undefined) {
      navigate("/");
    }
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <SidebarManager
            access={cookies.BRAUM_TOKEN?.role}
            isSidebar={isSidebar}
          />

          <main className="content overflow-y-auto">
            <Topbar setIsSidebar={setIsSidebar} />
            <Box sx={{ height: "90vh", overflowY: "scroll" }}>
              <Outlet />
            </Box>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default Template;
