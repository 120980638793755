/* eslint-disable array-callback-return */
import React from "react";
import { useCookiesLogin } from "../../config/cookies";
import RedSimulation from "../redsimulation";

const Redrural = () => {
  // props
  let uri = "redrural/";
  let type = "RED25 - Tri";
  let title = "REDRURAL";
  const { cookies } = useCookiesLogin();

  return (
    <RedSimulation title={title} simulationUri={uri} type_simulation={type} description={"Operações acima de R$ 100.000.00"} redrural={true} />
  );
};

export default Redrural;
