/* eslint-disable array-callback-return */
import { Backdrop, Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import * as yup from "yup";
import Header from "../../components/Header";
import { greenColor } from "../../config/colors";
import { useCookiesLogin } from "../../config/cookies";
import { HOST_SINGED } from "../../config/hosts";

const RegisterCustomer = () => {
  const { cookies } = useCookiesLogin();
  const [isLoading, setIsLoading] = useState(false);
  const [businessList, setBusinessList] = useState([]);
  const [bussinesSelected, setBussinesSelected] = useState(0);


  
  const initialValues = {
    nome: "",
    email: "",
    username: "",
    email: "",
    phone: "",
    role: "",
  };

  const validationSchema = yup.object().shape({
    nome: yup.string().required("Nome é obrigatório"),
    username: yup.string().required("Usuario deve receber um nome para o login.").min(6, "Insira com mais detalhes para melhor usabilidade da plataforma"),
    email: yup.string().email("Email inválido").required("Email é obrigatório"),
    phone: yup.string().email("Telefone inválido").required("Telefone é obrigatório"),
    role: yup.string().required("Perfil do usuário é obrigatório"),
  });

  const handleChangeBussines = (event) => {
    console.log(event.target.value)
    setBussinesSelected(event.target.value);
  };

  const handleSubmitForm = async (values, { setSubmitting }) => {
    setIsLoading(true);
    setSubmitting(true);

    const requestBody = {
      nome: values.nome,
      username: values.username,
      email: values.email,
      phone: values.phone,
      role: values.role,
    };

    // try {
    //   const response = await fetch(`${HOST_SINGED}/business/register/`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(requestBody),
    //   });

    //   const data = await response.json();

    //   if (response.ok) {
    //     toast.success("Negócio registrado com sucesso!", { theme: "dark" });
    //   } else {
    //     toast.error("Erro ao registrar o negócio. Tente novamente.", { theme: "dark" });
    //   }
    // } catch (error) {
    //   toast.error("Erro de rede ao registrar o negócio.", { theme: "dark" });
    // } finally {
    //   setIsLoading(false);
    //   setSubmitting(false);
    // }
  };





    useEffect(() => {
      async function fetchConsults() {
        try {
          const resposta = await fetch(`${HOST_SINGED}/reduto/business/all`, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          const infoData = await resposta.json();
          console.log(infoData)
          setBusinessList(infoData)
        } catch (error) {
          console.error("Erro ao buscar os dados:", error);
        }
      };

      fetchConsults();
      setIsLoading(false);
    }, []);

  return (
    <Box p={5}>
      <Header
        title={"Cadastrar novo cliente"}
        subtitle="Cadastro de cliente sem analise preditiva dos dados."
      />
      <Box mt={1}>
        <ToastContainer />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmitForm}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                <TextField
                  fullWidth
                  variant="filled"
                  label="Nome"
                  color="secondary"
                  value={values.nome}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="nome"
                  error={!!touched.nome && !!errors.nome}
                  helperText={touched.nome && errors.nome}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  label="E-mail"
                  color="secondary"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="email"
                  error={!!touched.razaoSocial && !!errors.razaoSocial}
                  helperText={touched.razaoSocial && errors.razaoSocial}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  label="Telefone"
                  color="secondary"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="telefone"
                  error={!!touched.cnpj && !!errors.cnpj}
                  helperText={touched.cnpj && errors.cnpj}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  label="Função"
                  color="secondary"
                  value={values.role}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="funcao"
                  error={!!touched.telefone && !!errors.telefone}
                  helperText={touched.telefone && errors.telefone}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  label="Nome do login do usuário"
                  color="secondary"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="username"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2" }}
                />
                
                <Box sx={{ width: 300, margin: '0 auto' }}>
                  <FormControl fullWidth>
                    <InputLabel id="business-select-label">Associcar a empresa
                    </InputLabel>
                    <Select
                      labelId="business-select-label"
                      id="business-select"
                      value={businessList}
                      label="Negócio"
                      onChange={handleChangeBussines}
                    >
                      <MenuItem value="">
                        <em>Selecione...</em>
                      </MenuItem>
                      
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ gridColumn: "span 2" }}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={isLoading || !values.nome || !!Object.keys(errors).length}
                  >
                    Cadastrar
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>

        <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
          <CircularProgress color="inherit" sx={{ color: greenColor }} />
        </Backdrop>
      </Box>
    </Box>
  );
};

export default RegisterCustomer;
