import axios from "axios";
import { CreateData } from "../config/createData";
import { HOST_BRAUM } from "../config/hosts";

import { login } from "../redux/actions";
import store from "../redux/store";

export default class Braum {
  constructor(username, password) {
    this.username = username;
    this.password = password;
    this.response = {};
  }

  // Realizando o login:
  async login() {
    const payload = CreateData.payloadLogin(this.username, this.password);
    const config = { headers: { "Content-type": "application/json" } };

    try {
      this.response = await axios.post(`${HOST_BRAUM}/login`, payload, config);

      return {
        data: this.response.data,
        status: this.response.status,
      };
    } catch (error) {
      console.log(error, "error");
      const statusError = error.message;
      return {
        status: statusError,
        data: null,
      };
    }
  }

  // Realizando o armazenamento dos dados na store (Redux):
  async store() {
    try {
      const currentData = this.response.data;
      store.dispatch(login(currentData));
      return {
        status: 200,
      };
    } catch (e) {
      return {
        status: 403,
      };
    }
  }

  // Realizar o login automático com o token armazenado em cache:
  async automaticLogin(payload) {
    try {
      const response = await axios.get(`${HOST_BRAUM}/auth/session`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${payload.token}`,
        },
      });

      // Formatando a chave ".user" para ".data":
      let currentData = response?.data?.data;
      currentData.data = currentData.user;
      delete currentData.user;
      this.response = currentData;
      this.store();

      return {
        status: 200,
      };
    } catch (e) {
      return {
        status: 400,
      };
    }
  }

  async updatePassword(payload) {
        try {
            const response = await axios.patch(`${HOST_BRAUM}/users/access/${payload.id}`,{
                password: payload.password,
                isFirstAccess: false
            },{
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                }
            })
            
            return {
              status: 200,
            };
        } catch(e) {
            return {
                status: 400,
            }
        }
    }
}
