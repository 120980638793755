import { RouteAccess } from "./routes";

export class RouterManager {
  constructor(access) {
    this.access = access;
  }

  manager() {
    const routeAccess = new RouteAccess(this.access);

    return routeAccess;
  }
}
