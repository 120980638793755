/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  TextField,
  useTheme
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import Header from "../../components/Header";
import { useCookiesLogin } from "../../config/cookies";
import { tokens } from "../../theme";
import FormSerasa from "./formSerasa";

import { HOST_REDUTO } from "../../config/hosts";
// let INSTALLMENTS = 18;

const cpfCnpjRegex =
  /^(\d{3})(\d{3})(\d{3})(\d{2})$|^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;

const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
      valueIsNumericString
      prefix="R$ "
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const redInstallment = {
  "RED 25 - Mes": 12,
  "RED 25 - Sem": 2,
  "RED 25 - Tri": 4
}

const RedSimulation = ({ title, simulationUri, type_simulation, description, redrural }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { cookies } = useCookiesLogin();
  const { id } = cookies.BRAUM_TOKEN;


  const userID = cookies.BRAUM_TOKEN.id;
  const [display, setDisplay] = useState(false);


  const [algorithm, setAlgorithm] = useState([]);
  const [open, setOpen] = useState(false);


  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState({});

  const [errorAmount, setErrorAmount] = useState(false);

  const [showFormSerasa, setShowFormSerasa] = useState(false);
  const [showButtonFormSerasa, setShowButtonFormSerasa] = useState(true);

  const [amount, setAmount] = useState(0);

  const columns = [
    { field: 'id', headerName: 'Qtde. de Duplicatas', width: 120 },
    {
      field: 'pmt_money',
      headerName: 'Valor das Duplicatas',
      width: 150,
      editable: true,
    },
    {
      field: 'total_real',
      headerName: 'Valor da Nota Fiscal',
      width: 150,
      editable: true,
    }
  ];

  const handleOpen = () => {
    setOpen(!open);
  };
  

  const handleRowSelection = (newSelection) => {
    if (newSelection.length === 1) {
      setSelectedRow(newSelection[0]);
      const selectedRowIndex = newSelection[0];
      const selectedRow = algorithm.find((row) => row.id === selectedRowIndex);
      setSelectedRowData(selectedRow);
    } else {
      setSelectedRow(null);
      setSelectedRowData({});
    }
  };

  useEffect(() => { }, [amount]);

  const valueParcel = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
  ];

  useEffect(() => {
    document.title = `RED 25 - Análise`;
  }, []);

  async function handleRedSimulation(amount) {
    setAmount(amount);

    const url = `${HOST_REDUTO}/reduto/red25/simulate`;
    
    const data = {
      amount: parseFloat(amount),
      type_simulation: type_simulation,
    };

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin':'*'
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        const objectWithIds = []

        result["steps"].map((data, index) => {
          const insertId = {
            id: index + 1,
            ...data
          }

          objectWithIds.push(insertId);
        })

        setAlgorithm(objectWithIds);
        setDisplay(true);
      })
      .catch((error) => {
        toast.error("Erro ao simular. Tente novamente ou entre em contato com o suporte", { theme: "dark" });
      });
  }


  function handleErrorAmount() {
    setTimeout(() => {
      setErrorAmount(false);
    }, 5000);
    return <p style={{ color: '#f77676' }}>Valor invalido, por favor insira o valor corretamente.</p>
  }

  let label = redrural == true ? "Simulação valores acima de R$ 100.000,00": "Simular valores acima de R$5.000,00"


  return (
    <>
      <Box m="20px">
        {redrural == true ? (
          <Header title={`${title}`} subtitle={`${description}`} />
        ) : (
          <Header
            title={`${title}`}
            subtitle="Análises de crédito equipe Reduto"
          />
        )}

        <Box>
          <Box>
            <Formik
              initialValues={initialValuesRedSimulation}
              validationSchema={RedSimulationSchema}
            >
              {({ errors, touched, values, handleBlur, handleChange }) => (
                <form
                  onSubmit={async (event) => {
                    event.preventDefault();

                    if (values.amount === 0) {
                      setErrorAmount(true);
                      return;
                    }

                    await handleRedSimulation(values.amount);
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    label={label}
                    color="secondary"
                    value={values.amount}
                    handleBlur={handleBlur}
                    onChange={handleChange}
                    sx={{ gridColumn: "span 2" }}
                    name="amount"
                    id="formatted-numberformat-input"
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    error={!!touched.amount && !!errors.amount}
                    helperText={touched.amount && errors.amount}
                  />

                  {errorAmount === true ? handleErrorAmount() : false}

                  <Button
                    sx={{
                      width: 140,
                      marginY: 3,
                      paddingX: 1,
                      paddingY: 1,
                    }}
                    type="submit"
                    color="secondary"
                    variant="outlined"
                  >
                   {title}
                  </Button>
                </form>
              )}
            </Formik>

            {display === true ? (
              <Box sx={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={algorithm}
                  columns={columns}
                  selectionModel={selectedRow !== null ? [selectedRow] : []}
                  onSelectionModelChange={handleRowSelection}
                  sx={{
                    boxShadow: 2,
                    border: `2px solid ${colors.grey[500]}`,
                    borderColor: "secondary",
                    "& .MuiDataGrid-cell:hover": {
                      color: "secondary",
                    },
                    "& .MuiCheckbox-root svg": {
                      width: 16,
                      height: 16,
                      backgroundColor: "transparent",
                      border: `1px solid ${
                        theme.palette.mode === "dark"
                          ? `${colors.grey[100]}`
                          : `rgb(67, 67, 67)`
                      }`,
                      borderRadius: 2,
                    },
                    "& .MuiCheckbox-root svg path": {
                      display: "none",
                    },
                    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg":
                      {
                        backgroundColor: colors.greenAccent[400],
                        borderColor: colors.greenAccent[400],
                      },
                    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after":
                      {
                        position: "absolute",
                        display: "table",
                        border: `2px solid ${colors.greenAccent[700]}`,
                        borderTop: 0,
                        borderLeft: 0,
                        transform: "rotate(45deg) translate(-50%,-50%)",
                        opacity: 1,
                        transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
                        content: '""',
                        top: "50%",
                        left: "39%",
                        width: 5.71428571,
                        height: 9.14285714,
                      },
                    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
                      {
                        width: 8,
                        height: 8,
                        backgroundColor: "#1890ff",
                        transform: "none",
                        top: "39%",
                        border: 0,
                      },
                  }}
                  checkboxSelection={true}
                  disableRowSelectionOnClick
                />
                {showButtonFormSerasa === true ? (
                  <Button
                    sx={{
                      width: 140,
                      marginY: 3,
                      paddingX: 1,
                      paddingY: 1,
                    }}
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      setShowFormSerasa(true);
                      setShowButtonFormSerasa(!showButtonFormSerasa);
                    }}
                  >
                    Analise de Crédito
                  </Button>
                ) : null}
              </Box>
            ) : null}
          </Box>
        </Box>
        {showFormSerasa === true ? (
          <FormSerasa
            algorithm={algorithm}
            installments={selectedRow}
            amount={amount}
            simulationUri={simulationUri}
            title={title}
          />
        ) : null}
      </Box>
    </>
  );
};

const initialValuesRedSimulation = {
  amount: 0,
};

const RedSimulationSchema = yup.object().shape({
  amount: yup
    .number()
    .min(5000, "O valor mínimo para análise de crédito é de R$5.000,00")
    .max(25000, "O valor máximo para análise de crédito é de R$25.000,00")
    .required("Informe um valor para a análise de crédito"),
});

export default RedSimulation;
