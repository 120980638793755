import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Modal, TextField, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState } from "react";
import Header from "../../components/Header";
import { maskCep, maskCpf, maskPhone } from "../../config/masks";
import { mockDataContacts } from "../../data/mockData";
import { tokens } from "../../theme";

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedContact, setEditedContact] = useState(null);

  const handleEditClick = (id) => {
    setSelectedRow(id);
    setIsModalOpen(true);
    const contact = mockDataContacts.find((c) => c.id === id);
    setEditedContact(contact);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setIsModalOpen(false);
    setEditedContact(null);
  };

  const handleSaveContact = () => {
    // Implemente aqui a lógica para salvar as alterações no contato
    handleCloseModal();
  };

  const handleCancelEdit = () => {
    handleCloseModal();
  };

  const handleFieldChange = (field, value) => {
    setEditedContact((prevContact) => ({
      ...prevContact,
      [field]: value,
    }));
  };

  const columns = [
    {
      field: "edit",
      headerName: "Editar",
      sortable: false,
      flex: 0.5,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Box
          component={EditIcon}
          color="primary"
          sx={{ cursor: "pointer" }}
          onClick={() => handleEditClick(params.id)}
        />
      ),
    },
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "cpf",
      type: 'text',
      headerName: "CPF",
      headerAlign: "left",
      align: "left",
      valueFormatter: (params) => maskCpf(params.value.toString())
    },
    {
      field: "mobile",
      headerName: "Número do celular",
      flex: 1,
      valueFormatter: (params) => maskPhone(params.value.toString())
    },
    {
      field: "locality",
      headerName: "Localidade",
      flex: 1,
    },
    {
      field: "adress",
      headerName: "Endereço",
      flex: 1,
    },
    {
      field: "cep",
      headerName: "CEP",
      flex: 1,
      valueFormatter: (params) => maskCep(params.value.toString())
    },
  ];

  return (
    <Box m="20px">
      <Header title="CLIENTES" subtitle="Lista de todos os clientes" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={mockDataContacts}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          onRowClick={(params) => handleEditClick(params.id)}
          selectionModel={selectedRow ? [selectedRow] : []}
        />
      </Box>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2>Editar Contato</h2>
          {editedContact && (
            <Box sx={{width: 450, display: 'flex', flexDirection: 'column', gap: 2}}>
              <TextField
                label="Nome"
                value={editedContact.name}
                onChange={(e) => handleFieldChange("name", e.target.value)}
              />
              <TextField
                label="CPF"
                value={editedContact.cpf}
                onChange={(e) => handleFieldChange("cpf", e.target.value)}
              />
              <TextField
                label="Número do Celular"
                value={editedContact.mobile}
                onChange={(e) => handleFieldChange("mobile", e.target.value)}
              />
              <TextField
                label="Localidade"
                value={editedContact.locality}
                onChange={(e) => handleFieldChange("locality", e.target.value)}
              />
              <TextField
                label="Endereço"
                value={editedContact.adress}
                onChange={(e) => handleFieldChange("adress", e.target.value)}
              />
              <TextField
                label="CEP"
                value={editedContact.cep}
                onChange={(e) => handleFieldChange("cep", e.target.value)}
              />
              <Box sx={{ marginTop: "16px" }}>
                <Button variant="contained" onClick={handleSaveContact}>
                  Salvar
                </Button>
                <Button variant="contained" onClick={handleCancelEdit} sx={{ marginLeft: "8px" }}>
                  Cancelar
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default Contacts;
