import {
  Box,
  Button,
  // CircularProgress,
  // FormControl,
  // FormHelperText,
  // Grid,
  // InputLabel,
  // Select,
  // Table,
  // TableBody,
  // TableCell,
  // TableContainer,
  // TableHead,
  // TableRow,
  // useMediaQuery,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../components/Header";
import { useCookiesLogin } from "../../config/cookies";
import { tokens } from "../../theme";

import Braum from '../../services/braum';

const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center", // Centraliza horizontalmente
      justifyContent: "center", // Centraliza verticalmente
      height: "80vh", // Define a altura para ocupar a altura total da tela
      background: "#1e1e1e", // Cor de fundo do container principal
      color: "#fff", // Cor do texto branco
    },
    title: {
      fontSize: "20px",
    },
    textField: {
      marginBottom: "20px",
      background: "#fff",
    },
    button: {
      backgroundColor: "#83d84d",
      color: "#000",
      padding: "10px 20px",
      border: "none",
      cursor: "pointer",
      fontSize: "16px",
    },
    buttonHover: {
      backgroundColor: "#0056b3",
    },
    buttonMargin: {
      marginBottom: "10px", // Adiciona espaço entre os botões
    },
  };
  

export default function ComponentProfile() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { cookies } = useCookiesLogin();
    const response = cookies.BRAUM_TOKEN || {};
    const navigate = useNavigate();
    const navigateHome = () => navigate("/app");

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
      document.title = 'Perfil';
    }, []);
  
    useEffect(() => {
      document.title = 'Perfil';
    }, []);

    return (
      <Box mx="30px">
          <Header title="Perfil" subtitle={`Olá, ${response.name}!`} />
       
          <Box display={"flex"} alignItems={"center"} flexDirection={"column"} marginTop={"15px"}>
        
            <Box
              textAlign={"center"}
              justifyContent={"center"}
              width={"300px"}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography fontSize={20}>Altere a sua senha</Typography>
              <TextField
              styel={{color: colors.greenAccent[400]}}
                label="Nova senha"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type="password"
              ></TextField>
        
              <TextField
              style={{marginTop: "10px", color: colors.greenAccent[400]}}
                label="Confirme a nova senha"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                type="password"
              ></TextField>
        
              <Button
                style={{ ...styles.button, marginTop: "10px" }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = styles.buttonHover.backgroundColor;
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = styles.button.backgroundColor;
                }}
                onClick={async () => {
                  <ToastContainer />

                  const payload = {
                    password: password,
                    id: response.id,
                  };
        
                  const braum = new Braum(response.username, response.password);
                  const res = await braum.updatePassword(payload);
                  if (res.status === 200) {
                    toast.success("Senha alterada com sucesso", { theme: "dark" });
                  } else {
                    toast.error("Erro ao alterar a senha", { theme: "dark" });
                  }
                  navigateHome();
                }}
              >
                Alterar
              </Button>
            </Box>
          </Box>
        </Box>
      );    
}
