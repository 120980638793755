import { Box, Button, Dialog, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useState } from "react";
import { tokens } from "../theme";

import { HOST_SINGED } from "../config/hosts";

const ModalFile = ({isOpen, setOpen, keyProposal, client_name, business_name}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [file, setFile] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const formData = new FormData();
        formData.append("file", file);
    
        try {
            const response = await fetch("https://appsup4tech.com:8001/reduto/boleto/upload", {
                method: "POST",
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const formDataS3 = new FormData();
            formDataS3.append("file", file);
            formDataS3.append("business", business_name);
            formDataS3.append("client_name", client_name);
            formDataS3.append("proposal_id", keyProposal);

            const res = await fetch("https://appsup4tech.com:8001/reduto/boleto/send", {
                method: "POST",
                body: formDataS3
            })

            if (!res.ok) {
                toast.error("Falha no envio do arquivo!", {theme: "dark"});
                throw new Error(`HTTP error! Status: ${response.status}`);
            } else {
                const updateStatus = await fetch(
                  `${HOST_SINGED}/reduto/proposal/finish/` + keyProposal,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "*/*",
                      "Access-Control-Allow-Origin": "*",
                    },
                  }
                );

                if (!updateStatus.ok) {
                    toast.error("Falha no envio do arquivo!", {theme: "dark"});
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                toast.success("Arquivo enviado com sucesso!", {theme: "dark"});
                window.location.reload();
            }
        } catch (error) {
            console.error("Error during fetch:", error);
        }

    };

    return(
        <Dialog open={isOpen}>
            <ToastContainer />
            <DialogTitle>
            <Typography
                variant="h3"
                color={colors.greenAccent[400]}
            >
                Upload de arquivo
            </Typography>
            
            </DialogTitle>

            <DialogContent>
                <Box display="flex" flexDirection="column" gap={3}>
                    <Typography
                        variant="h5"
                    >
                        Adicione a nota fiscal referente à proposta
                    </Typography>

                    <input type="file" name="file" onChange={(e) => setFile(e.target.files[0])} />
                        
                    <Box display="flex" alignContent="center" justifyContent="space-around">
                        <form onSubmit={handleSubmit}>
                            <Button 
                                type="submit"
                                variant="contained" 
                                color="secondary"
                                style={{marginRight: 10}}
                                disabled={!file}
                            >
                                Enviar arquivo
                            </Button>
                            <Button 
                                onClick={() => setOpen(!isOpen)} 
                                variant="contained" 
                                color="secondary"
                                style={{marginLeft: 10}}
                            >
                                Cancelar
                            </Button>
                        </form>
                    </Box>
                </Box>
            </DialogContent>

        </Dialog>
    ); 
}

export default ModalFile;
