import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useCookiesLogin } from "../../config/cookies";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  Collapse,
  Typography,
  Grid
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { greenColor } from "../../config/colors";

import { HOST_SINGED } from "../../config/hosts";

const regexCPF = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
const regexPhone = /^(\d{2})(\d{1})?(\d{4})(\d{4})$/;

const ClientTable = () => {
  const { cookies } = useCookiesLogin();
  const response = cookies.BRAUM_TOKEN || {};
  const role = response.role;
  let client_url;

  const [clients, setClients] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [consulta, setConsulta] = useState("");

  const [open, setOpen] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    switch (role) {
      case "admin":
        client_url = `${HOST_SINGED}/reduto/client/all`;
        break;
      case "consultant":
        const business_id = response.bussines_id;
        client_url =
          `${HOST_SINGED}/reduto/business/clients/` + business_id;
        break;
      default:
        console.log("INVALID");
    }

    fetch(client_url, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        setClients(data);
        setIsLoading(false);
      });
  }, [role, response]);

  useEffect(() => {
    document.title = "Clientes";
  }, []);

  const handleSortChange = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
  };

  const getSortIcon = (field) => {
    if (sortBy === field) {
      return sortOrder === "asc" ? (
        <ArrowUpwardIcon color="white" />
      ) : (
        <ArrowDownwardIcon color="white" />
      );
    }
    return null;
  };

  const filteredClients = clients.filter((cliente) =>
    cliente.fields.name.toLowerCase().includes(consulta.toLowerCase())
  );

  const sortedClients = [...filteredClients].sort((a, b) => {
    if (sortBy === "name") {
      return sortOrder === "asc"
        ? a.fields.name.localeCompare(b.fields.name)
        : b.fields.name.localeCompare(a.fields.name);
    } else if (sortBy === "official_name") {
      return sortOrder === "asc"
        ? a.fields.business_official_name.localeCompare(b.fields.business_official_name)
        : b.fields.business_official_name.localeCompare(a.fields.business_official_name);
    } else if (sortBy === "score") {
      return sortOrder === "asc"
        ? parseFloat(a.fields.score) - parseFloat(b.fields.score)
        : parseFloat(b.fields.score) - parseFloat(a.fields.score);
    }
    return 0;
  });

  const renderTableRows = (data) => {
    return data.map((row) => (
      <>
      <TableRow
        key={row.pk}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell>
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setOpen((prevState) => {
                    return { ...prevState, [row.pk]: !open[row.pk] }
                  });
                }}
              >
                {open[row.pk] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
          </TableCell>
        <TableCell component="th" scope="row">
          {row.fields.name.toUpperCase()}
        </TableCell>
        <TableCell align="left">{row.fields.business_trade_name === "" ? row.fields.business_official_name.toUpperCase() : row.fields.business_trade_name.toUpperCase()}</TableCell>
        <TableCell align="left">{row.fields.score}</TableCell>
      </TableRow>
      <TableRow>
        <Collapse in={open[row.pk]} timeout="auto" unmountOnExit> 
          <Grid container spacing={4} p={3}>
            <Grid item xs={3}>
              <Typography variant="h5" color={greenColor}>
                CPF/CNPJ
              </Typography>
              <Typography>
                {row.fields.cpf_client.length === 11 ? row.fields.cpf_client.replace(regexCPF, "$1.$2.$3-$4") : row.fields.cpf_client.replace(regexCNPJ, "$1.$2.$3/$4-$5")}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h5" color={greenColor}>
                Email
              </Typography>
              <Typography>
                {row.fields.email}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="h5" color={greenColor}>
                Telefone
              </Typography>
              <Typography>
                {row.fields.phone.split(',').map((tel) => tel.replace(regexPhone, "($1) $2 $3-$4 | "))}
              </Typography>
            </Grid>
          </Grid>
        </Collapse>
      </TableRow>
    </>
    ));
  };

  const limparFiltro = () => {
    setConsulta("");
    setSortBy("");
    setSortOrder("asc");
  };

  return (
    <>
      <Box m="20px">
        <Header
          title="Clientes"
          subtitle="Aqui você pode encontrar informações sobre os seus clientes."
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="Pesquisar por nome"
            variant="outlined"
            fullWidth
            value={consulta}
            onChange={(e) => setConsulta(e.target.value)}
          />
          <Button
            variant="outlined"
            color="secondary"
            onClick={limparFiltro}
            style={{ marginLeft: "10px" }}
          >
            Limpar Filtro
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("name")}
                    startIcon={getSortIcon("name")}
                    sx={{ color: "white" }}
                  >
                    Nome
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("official_name")}
                    startIcon={getSortIcon("official_name")}
                    sx={{ color: "white" }}
                  >
                    Revenda
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("score")}
                    startIcon={getSortIcon("score")}
                    sx={{ color: "white" }}
                  >
                    Score / NÍVEL DA OPERAÇÃO
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableRows(sortedClients)}</TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" sx={{ color: greenColor }} />
      </Backdrop>
    </>
  );
};

export default ClientTable;
