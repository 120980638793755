import { useTheme } from "@emotion/react";
import {
  Box,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import { useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookiesLogin } from "../../config/cookies";
import { tokens } from "../../theme";

const regexCEP = /^(\d{5})(\d{3})$/;
const regexCPF = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
const regexPhone = /^(\d{2})(\d{1})?(\d{4})(\d{4})$/;

const HandlerSerasaSocoreCPF = ({
  dataAPI
}) => {
  const [name, setName] = useState("");
  const [taxIdNumber, setTaxIdNumber] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [taxIdOrigin, setTaxIdOrigin] = useState("");
  const [bussinesRelation, setBussinesRelation] = useState([]);
  const [score, setScore] = useState("");
  const [eventProbability, setEventProbability] = useState("");

  const { cookies } = useCookiesLogin();
  const { id, bussines_id } = cookies.BRAUM_TOKEN;

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const navigateToDashboard = () => navigate("/app");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const data = {
    _id: "6616d60e266e4527c1998e28",
    data: {
      _id: "6616d60e266e4527c1998e28",
      inputs: {
        document: "46555054867",
        datasets: ["BASIC_INFO", "SERASA_AGRO_SCORE"],
      },
      datasets: [
        {
          type: "BASIC_INFO",
          status: "COMPLETED",
          results: [
            {
              basicData: {
                taxIdNumber: "46555054867",
                name: "WELLINGTON DA SILVA BEZERRA",
                birthDate: "1997-12-05",
                motherName: "KELY CRISTINA MORAES RIBEIRO DA SILVA",
                taxIdOrigin: "RECEITA FEDERAL",
                taxIdStatus: "REGULAR",
                nameUniquenessScore: null,
              },
              emails: [],
              phones: ["(11) 39713560", "(11) 990013439"],
              addresses: [
                {
                  type: "R",
                  addressMain: "JOAO BATISTA FIGUEIREDO",
                  number: "259",
                  complement: "C 1",
                  neighborhood: "JD CIDADE PIRITUBA",
                  zipcode: "02943020",
                  city: "SAO PAULO",
                  uf: "SP",
                  ibgeCode: "3550308",
                },
                {
                  type: "R",
                  addressMain: "JOAO BATISTA FIGUEIREDO",
                  number: "324",
                  complement: "C 1",
                  neighborhood: "JD CIDADE PIRITUBA",
                  zipcode: "02943020",
                  city: "SAO PAULO",
                  uf: "SP",
                  ibgeCode: "3550308",
                },
                {
                  type: "R",
                  addressMain: "JOAO BATISTA FIGUEIREDO",
                  number: "259",
                  complement: "Sem informação",
                  neighborhood: "JD CIDADE PIRITUBA",
                  zipcode: "02943020",
                  city: "SAO PAULO",
                  uf: "SP",
                  ibgeCode: "3550308",
                },
                {
                  type: "R",
                  addressMain: "JOAO BATISTA FIGUEIREDO",
                  number: "259",
                  complement: "CS 2",
                  neighborhood: "JD CIDADE PIRITUBA",
                  zipcode: "02943020",
                  city: "SAO PAULO",
                  uf: "SP",
                  ibgeCode: "3550308",
                },
                {
                  type: "R",
                  addressMain: "JOAO BATISTA FIGUEIREDO",
                  number: "324",
                  complement: "Sem informação",
                  neighborhood: "JD CIDADE PIRITUBA",
                  zipcode: "02943020",
                  city: "SAO PAULO",
                  uf: "SP",
                  ibgeCode: "3550308",
                },
                {
                  type: "R",
                  addressMain: "JOAO BATISTA FIGUEIREDO",
                  number: "330",
                  complement: "Sem informação",
                  neighborhood: "JD CIDADE PIRITUBA",
                  zipcode: "02943020",
                  city: "SAO PAULO",
                  uf: "SP",
                  ibgeCode: "3550308",
                },
                {
                  type: "TV",
                  addressMain: "APUCARANA",
                  number: "81",
                  complement: "Sem informação",
                  neighborhood: "JD LUCIANA",
                  zipcode: "08575455",
                  city: "ITAQUAQUECETUBA",
                  uf: "SP",
                  ibgeCode: "3523107",
                },
              ],
              relatedPeople: [
                {
                  document: "32509951858",
                  name: "KELY CRISTINA MORAES RIBEIRO DA SILVA",
                  relation: "MAE",
                },
              ],
              businessRelationships: [
                {
                  document: "35014434000125",
                  name: "WELLINGTON DA SILVA BEZERRA",
                  relation: "SÓCIO",
                  share: "100.0",
                },
                {
                  document: "49595503000146",
                  name: "REDUTO CAPITAL SERV EM TI LTDA",
                  relation: "SÓCIO",
                  share: "0.0",
                },
              ],
            },
          ],
        },
        {
          type: "SERASA_AGRO_SCORE",
          status: "COMPLETED",
          results: [
            {
              id: "115886",
              document: "FjRAdRMBxZhYvcz9bEN4zYZPOf7uJfTe+rd3JezQ1WE=",
              requester: "34849124000168",
              score: 219,
              event_probability: 0.444002,
              reference_date: "2024-04-06",
              created_at: "2024-04-10T18:10:22.685Z",
              updated_at: "2024-04-10T18:10:22.685Z",
            },
          ],
        },
      ],
    },
  };

  let basicInfo = dataAPI.data.datasets.find(
    (basicInfo) => basicInfo.type == "BASIC_INFO"
  );
  let serasaScore = dataAPI.data.datasets.find(
    (basicInfo) => basicInfo.type == "AGRO_SCORE_CPF"
  );

    // já existe a consulta
  useEffect(() => {
    

    console.log(basicInfo.results[0]);

    setName(basicInfo.results[0].basicData.name);
    setTaxIdNumber(basicInfo.results[0].basicData.taxIdNumber);
    setBirthDate(basicInfo.results[0].basicData.birthDate);
    setTaxIdOrigin(basicInfo.results[0].basicData.taxIdOrigin);
    setBussinesRelation(basicInfo.results[0].businessRelationships);

    setScore(serasaScore.results[0].score);
    setEventProbability(serasaScore.results[0].event_probability);
  }, []);

  return (
    <>
      <ToastContainer />
      <div
        className=""
        style={{
          marginTop: 50,
        }}
      >
        <Typography variant="h3" color={colors.grey[500]} gutterBottom>
          Dados informativos do cliente
        </Typography>
        <Grid container spacing={4} marginBottom={3}>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Documento
            </Typography>
            <Typography fontSize={22}>
              {taxIdNumber.replace(regexCPF, "$1.$2.$3-$4")}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Nome
            </Typography>
            <Typography fontSize={22}>
              {basicInfo.results[0].basicData.name}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Data de Nascimento
            </Typography>
            <Typography fontSize={22}>{birthDate}</Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Origem Dados
            </Typography>
            <Typography fontSize={22}>{taxIdOrigin}</Typography>
          </Grid>
        </Grid>
      </div>
      <div
        className=""
        style={{
          marginTop: 30,
        }}
      >
        <Typography variant="h3" color={colors.grey[500]} gutterBottom>
          Informações para análise de crédito
        </Typography>
        <Grid container spacing={4} marginBottom={3} marginTop={3}>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Score
            </Typography>
            <Typography
              color={
                score < 333 ? "#ff5050" : score < 666 ? "#fff28b" : "secondary"
              }
              fontSize={22}
            >
              {score} de 1000
            </Typography>

            <GaugeChart
              id="gauge-chart2"
              nrOfLevels={20}
              percent={score / 1000}
              textColor="#f1f1f1"
              arcWidth={0.2}
              colors={["#FF0000", "#00FF00"]}
              formatTextValue={(value) => score}
              style={{
                fontWeight: 600,
              }}
            />
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Porcentagem de risco
            </Typography>
            <Typography fontSize={22}>
              {parseFloat(eventProbability * 100).toFixed(2)} %
            </Typography>
          </Grid>
        </Grid>
        <ScoreDescription />
      </div>
    </>
  );
};

const ScoreDescription = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* Imagem do Serasa Experian */}
      <Box
        sx={{ display: "flex", justifyContent: "center", marginBottom: 3 }}
      ></Box>
      <Typography
        variant="h4"
        align="left"
        gutterBottom
        color={colors.greenAccent["500"]}
        sx={{ fontSize: "2rem" }}
      >
        Entenda o RedutoScore
      </Typography>
      <Typography
        variant="h4"
        align="left"
        color="textSecondary"
        paragraph
        sx={{ fontSize: "1.25rem" }}
      >
        O RedScore é uma pontuação que vai de 0 a 1000 e reflete a
        probabilidade de um consumidor pagar suas contas em dia.
      </Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {/* Quadradinho Vermelho */}
        {/* <Grid item xs={12} sm={1}>
          <Box
            sx={{
              width: 24,
              height: 24,
              backgroundColor: "error.main",
              margin: "0 auto",
              borderRadius: 1,
            }}
          />
        </Grid> */}

        <Grid item xs={12} sm={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.5rem" }}>
              Score baixo
            </Typography>
            <Typography
              variant="h4"
              color="error"
              gutterBottom
              sx={{ fontSize: "2rem" }}
            >
              0 a 300
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontSize: "1.1rem" }}
            >
              Alto risco de inadimplência. Dificuldade em conseguir crédito.
            </Typography>
          </Paper>
        </Grid>

        {/* Quadradinho Amarelo */}
        {/* <Grid item xs={12} sm={1}>
          <Box
            sx={{
              width: 24,
              height: 24,
              backgroundColor: "warning.main",
              margin: "0 auto",
              borderRadius: 1,
            }}
          />
        </Grid> */}

        <Grid item xs={12} sm={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.5rem" }}>
              Score médio
            </Typography>
            <Typography
              variant="h4"
              color="warning.main"
              gutterBottom
              sx={{ fontSize: "2rem" }}
            >
              301 a 700
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontSize: "1.1rem" }}
            >
              Risco moderado. Chance razoável de conseguir crédito, mas com
              condições restritas.
            </Typography>
          </Paper>
        </Grid>

        {/* Quadradinho Verde */}
        {/* <Grid item xs={12} sm={1}>
          <Box
            sx={{
              width: 24,
              height: 24,
              backgroundColor: "success.main",
              margin: "0 auto",
              borderRadius: 1,
            }}
          />
        </Grid> */}

        <Grid item xs={12} sm={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.5rem" }}>
              Score alto
            </Typography>
            <Typography
              variant="h4"
              color="success.main"
              gutterBottom
              sx={{ fontSize: "2rem" }}
            >
              701 a 1000
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontSize: "1.1rem" }}
            >
              Baixo risco de inadimplência. Facilidade em obter crédito com boas
              condições.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Typography
        variant="body1"
        align="center"
        color="textSecondary"
        paragraph
        sx={{ marginTop: 3, fontSize: "1.25rem" }}
      >
        Score de crédito AGRO, focado nas movimentações e segmento do Agronegócio, utilizando o Serasa Experian como fonte de dados.
      </Typography>
    </Box>
  );
};
export default HandlerSerasaSocoreCPF;