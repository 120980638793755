import { useTheme } from "@emotion/react";
import { Add } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import { greenColor } from "../../config/colors";
import { useCookiesLogin } from "../../config/cookies";
import { HOST_SINGED } from "../../config/hosts";
import { getCep } from "../../services/cep";
import { tokens } from "../../theme";

const regexCEP = /^(\d{5})(\d{3})$/;
const regexCPF = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
const regexPhone = /^(\d{2})(\d{1})?(\d{4})(\d{4})$/;

const HandlerDataInfos = ({
  cpfcnpj,
  idConsult,
  tokenBrain,
  algorithm,
  isClient,
  amount,
  clientId,
  installments,
  simulationUri,
  title,
  noProposal
}) => {
  const [name, setName] = useState("");
  const [taxIdNumber, setTaxIdNumber] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [taxIdOrigin, setTaxIdOrigin] = useState("");
  const [score, setScore] = useState("");
  const [eventProbability, setEventProbability] = useState("");
  const [statusDate, setStatusDate] = useState("");
  const [risk, setRisk] = useState("");
  const [operability, setOperability] = useState("");
  const [taxRegime, setTaxRegime] = useState("");
  const [hasEmail, setHasEmail] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  
  const { cookies } = useCookiesLogin();
  const { id, bussines_id } = cookies.BRAUM_TOKEN;

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [phone, setPhone] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const navigateToSucess = () =>
    navigate("/app/successclient", {
      state: {
        message: "Cliente cadastrado com sucesso!",
      },
    });

  const navigateToDashboard = () => navigate("/app");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [displayNumberHome, setDisplayNumberHome] = useState(false);
  const [isCPF, setIsCPF] = useState(false);
  const [phoneList, setPhoneList] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  async function handleProposal(userIdProposal) {
    setIsLoading(true);

    fetch(`${HOST_SINGED}/reduto/proposal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      body: JSON.stringify({
        installments: installments,
        client_id: clientId ? clientId : userIdProposal,
        bussines_id: bussines_id,
        amount_total: amount,
        type_simulation: "red25",
        status: "CREATED",
        score: score ? score : operability,
        event_probability: eventProbability ? eventProbability : risk,
        name: name,
        value_installments: algorithm[installments - 1].pmt
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          return res;
        } else {
          toast.error("Não foi possível criar proposta, contate suporte!", { theme: "dark" });
        }
      })
      .then((res) => res.json())
      .then((data) => {
        fetch(`${HOST_SINGED}/${simulationUri}send_proposal`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          body: JSON.stringify({
            proposal: {
              user_id: clientId ? clientId : userIdProposal,
              amount_total: amount,
              algorithm: algorithm
            },
            personal: {
              name: name,
              email: cookies['BRAUM_TOKEN']['email']
            }
          }),
        })
        setIsLoading(false);
        toast.success("Proposta criada com sucesso!", { theme: "dark" });
        navigateToDashboard();
      }).catch(erro => {
        setIsLoading(false);
        return;
      })
  }

  useEffect(() => {
    async function handleGetInfo() {
      const request = await fetch(`${HOST_SINGED}/brain/info/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        body: JSON.stringify({
          id_consult: idConsult,
          token_brain: tokenBrain,
        }),
      });
      const response = await request.json();
      console.log(response, "response");
      
      const basic_info = response.datasets.filter(
        (data) => data.type === "BASIC_INFO"
      );
      const serasa_score = response.datasets.filter(
        (data) => data.type === "SERASA_AGRO_SCORE"
      );

      if (serasa_score.length !== 0) {
        setIsCPF(true);
        setEventProbability(serasa_score[0].results[0].event_probability);
        setScore(serasa_score[0].results[0].score);
        setTaxIdOrigin(basic_info[0].results[0].basicData.taxIdOrigin);
        setName(basic_info[0].results[0].basicData.name);

        // format date
        let timestamp = basic_info[0].results[0].basicData.birthDate;

        let date = new Date(timestamp);

        // Extract the components of the date
        var dayBirth = date.getDate();
        var monthBirth = date.getMonth() + 1; // Months are zero-based, so we add 1
        var yearBirth = date.getFullYear();

        var formattedBirthDate = `${dayBirth
          .toString()
          .padStart(2, "0")}/${monthBirth
          .toString()
          .padStart(2, "0")}/${yearBirth}`;

        setBirthDate(formattedBirthDate);
      } else {
        setIsCPF(false);

        // format date
        let timestamp =
          basic_info[0].results[0].basicData.taxIdStatusRegistrationDate;

        let date = new Date(timestamp);

        // Extract the components of the date
        var day = date.getDate();
        var month = date.getMonth() + 1; // Months are zero-based, so we add 1
        var year = date.getFullYear();

        var formattedDate = `${day.toString().padStart(2, "0")}/${month
          .toString()
          .padStart(2, "0")}/${year}`;

        setStatusDate(formattedDate);
        setName(basic_info[0].results[0].basicData.officialName);
        setRisk(basic_info[0].results[0].basicData.riskScreening);
        setOperability(basic_info[0].results[0].basicData.operabilityIndicator);
        setTaxRegime(basic_info[0].results[0].basicData.taxRegime);
      }

      setTaxIdNumber(basic_info[0].results[0].basicData.taxIdNumber);
    }  

    handleGetInfo();
  }, [idConsult, tokenBrain]);

  return (
    <>
      <ToastContainer />
      {isCPF ? (
        <Grid container spacing={4} marginBottom={3} marginTop={5}>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Documento
            </Typography>
            <Typography fontSize={22}>
              {taxIdNumber.replace(regexCPF, "$1.$2.$3-$4")}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Nome
            </Typography>
            <Typography fontSize={22}>{name}</Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Data de Nascimento
            </Typography>
            <Typography fontSize={22}>{birthDate}</Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Origem Dados
            </Typography>
            <Typography fontSize={22}>{taxIdOrigin}</Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Score
            </Typography>
            <Typography
              color={
                score < 333 ? "#ff5050" : score < 666 ? "#fff28b" : "secondary"
              }
              fontSize={22}
            >
              {score}
            </Typography>

            <GaugeChart
              id="gauge-chart2"
              nrOfLevels={20}
              percent={score / 1000}
              textColor="#f1f1f1"
              arcWidth={0.2}
              colors={["#FF0000", "#00FF00"]}
              formatTextValue={(value) => score}
              style={{
                fontWeight: 600,
              }}
            />
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Porcentagem de risco
            </Typography>
            <Typography fontSize={22}>
              {parseFloat(eventProbability * 100).toFixed(2)} %
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={4} marginBottom={3} marginTop={5}>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Documento
            </Typography>
            <Typography fontSize={22}>
              {taxIdNumber
                ? taxIdNumber.replace(regexCNPJ, "$1.$2.$3/$4-$5")
                : "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Nome
            </Typography>
            <Typography fontSize={22}>
              {name ? name : "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Data de fundação
            </Typography>
            <Typography fontSize={22}>
              {statusDate ? statusDate : "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Natureza jurídica
            </Typography>
            <Typography fontSize={22}>
              {taxRegime ? taxRegime : "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Risco
            </Typography>
            <Typography fontSize={22}>
              {risk ? risk : "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Indice da operação
            </Typography>
            <Typography fontSize={22}>
              {operability ? operability : "Não informado"}
            </Typography>
          </Grid>
        </Grid>
      )}

      {!isClient ? (
        <Box mt={10}>
          <Typography variant="h2" color={colors.grey[300]} marginBottom={5}>
            Dados Plataforma
          </Typography>
          <Formik
            initialValues={initialValuesLocale}
            validationSchema={localeSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form
                onSubmit={async (event) => {
                  setIsLoading(true);
                  handleSubmit(event);

                  const flist = phoneList.map((tel) =>
                    tel.replace(/[^\w]/gi, "")
                  );
                  

                  await fetch(`${HOST_SINGED}/client/`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      cpf_client: cpfcnpj,
                      cep: values.cep,
                      business_id: bussines_id,
                      score: score ? score : operability,
                      name: values.name,
                      email: !hasEmail
                        ? values.email
                        : cookies.BRAUM_TOKEN.email,
                      phone: flist.toString(),
                      brain_id: idConsult,
                      token_brain: tokenBrain,
                      event_probability: eventProbability
                        ? eventProbability
                        : risk,
                    }),
                  }).then(async (response) => {
                    if (response.status === 201) {
                      fetch(`${HOST_SINGED}/client/${cpfcnpj}`).then((res) => {
                        res.json().then(async (data) => {
                          await handleProposal(data.client_id);
                        });
                      });
                      navigateToSucess();
                    } else if (response.status === 400) {
                      response
                        .json()
                        .then((data) =>
                          toast.error(
                            "Erro ao cadastrar o cliente! Tente novamente ou entre em contato com o suporte",
                            { theme: "dark" }
                          )
                        );
                    }
                  }).catch(erro => {
                    setIsLoading(false);
                    return;
                  })

                  setIsLoading(false);
                }}
              >
                <Box
                  display="grid"
                  gap="30px"
                  marginBottom={5}
                  gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Nome"
                    placeholder="Nome do usuário dentro da plataforma."
                    color="secondary"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="email"
                    label="Email"
                    placeholder="Email principal para logar na plataforma."
                    color="secondary"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={hasEmail}
                    value={values.email}
                    name="email"
                    error={!!touched.email && !!errors.email && !hasEmail}
                    helperText={touched.email && errors.email && hasEmail}
                    sx={{ gridColumn: "span 2" }}
                  />

                  <FormControlLabel
                    label="Não possui email"
                    control={
                      <Checkbox
                        checked={hasEmail}
                        onChange={(e) => {
                          setHasEmail(e.target.checked);
                        }}
                        sx={{
                          color: "#1e1e1e",
                          backgroundColor: "#83d84d",
                          borderRadius: "10px",
                          width: "30px",
                          height: "30px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          ":hover": {
                            color: "#1e1e1e",
                            backgroundColor: "#83d84d",
                          },
                        }}
                      />
                    }
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Celular / Telefone"
                    color="secondary"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone.replace(regexPhone, "($1) $2$3-$4")}
                    name="phone"
                    error={
                      !!touched.phone &&
                      !!errors.phone &&
                      phoneList.length === 0
                    }
                    helperText={
                      touched.phone && errors.phone && phoneList.length === 0
                    }
                    sx={{ gridColumn: "span 2" }}
                  />

                  <Add
                    sx={{
                      color: "#1e1e1e",
                      backgroundColor: "#83d84d",
                      borderRadius: "10px",
                      width: "30px",
                      height: "30px",
                    }}
                    onClick={() => {
                      phoneList.push(values.phone.replace(/\Dg/, ""));
                      values.phone = "";
                    }}
                  />
                  {phoneList.length > 0 ? (
                    <Box>
                      <Typography>Telefones cadastrados</Typography>

                      {phoneList.map((tel) => (
                        <Typography>
                          {" "}
                          {tel.replace(regexPhone, "($1) $2 $3-$4")}{" "}
                        </Typography>
                      ))}
                    </Box>
                  ) : null}
                </Box>

                <Box>
                  <Typography
                    variant="h2"
                    color={colors.grey[300]}
                    marginBottom={4}
                  >
                    Endereço
                  </Typography>

                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="CEP"
                      color="secondary"
                      onBlur={handleBlur}
                      onChange={async (e) => {
                        handleChange(e);
                        if (e.target.value.replace(/\D/g, "").length === 8) {
                          const response = await getCep(
                            e.target.value.replace(/\D/g, "")
                          );
                          setCity(response.data.localidade);
                          setNeighborhood(response.data.bairro);
                          setAddress(response.data.logradouro);
                          setDisplayNumberHome(true);
                        }
                      }}
                      value={values.cep.replace(regexCEP, "$1-$2")}
                      name="cep"
                      error={!!touched.cep && !!errors.cep}
                      helperText={touched.cep && errors.cep}
                      sx={{ gridColumn: "span 2" }}
                    />
                  </Box>
                </Box>
                <Box
                  display="grid"
                  gap="30px"
                  marginBottom={5}
                  gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <Grid container spacing={2} marginBottom={2} marginTop={5}>
                    <Grid item xs={4} sx={{ marginY: 3 }}>
                      <Typography
                        variant="h4"
                        color={colors.grey[300]}
                        marginBottom={2}
                      >
                        Cidade
                      </Typography>
                      <Typography fontSize={22}>
                        {city ? city : "Não informado"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ marginY: 3 }}>
                      <Typography
                        variant="h4"
                        color={colors.grey[300]}
                        marginBottom={2}
                      >
                        Bairro
                      </Typography>
                      <Typography fontSize={22}>
                        {neighborhood ? neighborhood : "Não informado"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ marginY: 3 }}>
                      <Typography
                        variant="h4"
                        color={colors.grey[300]}
                        marginBottom={2}
                      >
                        Logradouro
                      </Typography>
                      <Typography fontSize={22}>
                        {address ? address : "Não informado"}
                      </Typography>
                    </Grid>
                    {displayNumberHome === true ? (
                      <Grid item xs={4} sx={{ marginY: 3 }}>
                        <Typography
                          variant="h4"
                          color={colors.grey[300]}
                          marginBottom={2}
                        >
                          Número
                        </Typography>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label="Número Residência"
                          color="secondary"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={hasNumber}
                          value={values.numberHome}
                          name="numberHome"
                          error={!!touched.numberHome && !!errors.numberHome}
                          helperText={touched.numberHome && errors.numberHome}
                          sx={{ gridColumn: "span 2" }}
                        />

                        <FormControlLabel
                          label="Não possui número"
                          control={
                            <Checkbox
                              checked={hasNumber}
                              onChange={(e) => {
                                setHasNumber(e.target.checked);
                              }}
                              sx={{
                                color: "#1e1e1e",
                                backgroundColor: "#83d84d",
                                borderRadius: "10px",
                                width: "30px",
                                height: "30px",
                                marginLeft: "10px",
                                marginRight: "10px",
                                marginTop: "10px",
                                ":hover": {
                                  color: "#1e1e1e",
                                  backgroundColor: "#83d84d",
                                },
                              }}
                            />
                          }
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                  <Box>
                    <Button
                      sx={{
                        width: 400,
                        marginY: 0,
                        paddingY: 2,
                      }}
                      type="submit"
                      color="secondary"
                      variant="contained"
                    >
                      Cadastrar Cliente
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
          <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
            <CircularProgress color="inherit" sx={{ color: greenColor }} />
          </Backdrop>
        </Box>
      ) : (
        <Box>
          {noProposal == true ? (
            <Typography variant="h5" color={colors.greenAccent[400]}>
              Avalie, repense e construa a melhor proposta para seu cliente com
              REDUTO SCORE.
            </Typography>
          ) : (
            <Button
              sx={{
                width: 400,
                marginY: 0,
                paddingX: 10,
                paddingY: 2,
              }}
              onClick={() => handleProposal()}
              color="secondary"
              variant="contained"
            >
              Enviar Proposta
            </Button>
          )}

          <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
            <CircularProgress color="inherit" sx={{ color: greenColor }} />
          </Backdrop>
        </Box>
      )}
    </>
  );
};

const initialValuesLocale = {
  name: "",
  cep: "",
  city: "",
  address: "",
  uf: "",
  email: "",
  phone: "",
  neighborhood: "",
};

const localeSchema = yup.object().shape({
  name: yup
    .string()
    .required("Precisa inserir um nome para o usuário na plataforma!")
    .required("Campo Obrigatório."),
  cep: yup
    .string()
    .max(11, "CEP com mais de 11 caracteres invalido!")
    .required("Campo Obrigatório.regexPhone"),
  city: yup.string().required("Campo Obrigatório."),
  address: yup.string(),
  numberHome: yup.number().positive().label("Número da Residência"),
  email: yup.string().email().required("Campo Obrigatório."),
  phone: yup.string().required("Campo Obrigatório."),
  neighborhood: yup.string(),
});

export default HandlerDataInfos;