import {
  Backdrop,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { greenColor } from "../../config/colors";
import { HOST_BRAUM, HOST_SINGED } from "../../config/hosts";
import { tokens } from "../../theme";
import LogoReduto from "./../../assets/logo.png";

export function Logo() {
  return (
    <img
      src={LogoReduto}
      alt="react logo"
      style={{
        width: 250,
        marginBottom: 50,
      }}
    />
  );
}

export default function ResetPassword() {
  const [userId, setUserId] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const navigateLogin = () => navigate("/");

  const generateRandomPassword = () => {
    const chars = "0123456789";
    const passwordLength = 6;
    let password = "";

    for (let i = 1; i <= passwordLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }

    setNewPassword(password);
    return password;
  };

  const sendWhatsappMessage = async (pass, phone) => {
    fetch(
      "https://api.z-api.io/instances/3CD44E6170A830862A74BE5925AA0C3E/token/4E78F5BF140932BAC976960F/send-text",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          "client-token": "F3d6adfc0622c4b9da6c56f53e3db64b7S",
        },
        body: JSON.stringify({
          phone: phone,
          message: `Olá, ${name}! Seguindo a sua solicitação de recuperação de senha, aqui está a nova senha de acesso da plataforma da Reduto Capital: ${pass}`,
        }),
      }
    ).then((res) => {
      if (res.status === 200) {
        toast.success("Nova senha gerada com sucesso", { theme: "dark" });
      } else {
        toast.error("Algo deu errado. Tente novamente", { theme: "dark" });
      }
    });
  };

  const sendEmail = async (pass, email) => {
    fetch(`${HOST_SINGED}/reduto/resetpassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      body: JSON.stringify({
        email: email,
        password: pass,
        name: name,
      }),
    });
  };

  const handleCheckUserExists = async () => {
    if (isLoading) return; // Impede chamada duplicada
    setIsLoading(true);

    try {
      const response = await fetch(`${HOST_BRAUM}/users/checkuser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        body: JSON.stringify({
          username: username,
        }),
      });

      
      if (response.status === 200) {
        const retorno = await response.json();
        setUserId(retorno.id);
        setContactPhone(retorno.phone.replace(/\D/g, ""));
        setName(retorno.name);

        const password = generateRandomPassword();
      
        if (retorno.id) {
          try {
            const response = await fetch(`${HOST_BRAUM}/users/access/${retorno.id}`, {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
              },
              body: JSON.stringify({
                password: password,
              }),
            });
             
            let respon = await response.json()
            console.log(response, "response1");
            console.log(respon, "response 2")
            if (response.status === 200) {
              await sendWhatsappMessage(password, retorno.phone);
              await sendEmail(password, retorno.email);
              navigateLogin();
            } else {
              toast.warning("Erro ao recuperar a senha", { theme: "dark" });
            }
          } catch (error) {
            toast.error("Erro ao redefinir a senha", { theme: "dark" });
          } finally {
            setIsLoading(false);
          }
        }
      } else {
        toast.warning("Usuário não encontrado", { theme: "dark" });
      }
    } catch (error) {
      toast.error("Erro ao verificar usuário", { theme: "dark" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (passnew) => {
    if (userId) {
      try {
        const response = await fetch(`${HOST_BRAUM}/users/access/${userId}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          body: JSON.stringify({
            password: passnew,
            isFirstAccess: true,
          }),
        });

        if (response.status === 200) {
          await sendWhatsappMessage(passnew);
          await sendEmail(passnew);
          navigateLogin();
        } else {
          toast.warning("Erro ao recuperar a senha", { theme: "dark" });
        }
      } catch (error) {
        toast.error("Erro ao redefinir a senha", { theme: "dark" });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          // await handleCheckUserExists();
        }}
      >
        <section className="w-full sm:fixed justify-center items-center bg-gradient-to-r from-[#0e0d0dee] via-[#090808] to-[#000000]">
          <ToastContainer />
          <section className="w-full h-screen justify-center rounded-md sm:rounded-none p-[20px] sm:flex sm:flex-col sm:justify-center sm:items-center text-center">
            <div className="w-full pb-[10px] flex flex-col justify-center items-center">
              <Logo />
              <div className="w-full h-full mt-[20px] flex flex-col items-center text-left">
                <div className="w-full h-[60px] sm:max-w-[400px] border-b-2 border-gray-400 hover:border-sky-500 duration-100">
                  <label className="text-[#fafafa] text-lg">Usuário</label>
                  <input
                    type="text"
                    className="w-full bg-[#0f1010] p-2 outline-0 text-[#83d84d] mt-[3px]"
                    onChange={(e) => setUsername(e.target.value)}
                    id="user"
                    required
                  />
                </div>

                <div className="w-full sm:max-w-[400px] mt-[10px]">
                  <button
                    type=""
                    className="w-full h-[40px] bg-[#83d84d] rounded-md text-lg font-bold"
                    disabled={isLoading}
                    onClick = { handleCheckUserExists }
                  >
                    {isLoading ? "Carregando..." : "Recuperar senha"}
                  </button>
                </div>
              </div>
            </div>
          </section>
          <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
            <CircularProgress color="inherit" sx={{ color: greenColor }} />
          </Backdrop>
        </section>
      </form>
    </div>
  );
}
