import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';

const Resume = ({ nome, email, quantidade, valor, resumeId }) => {
    const dataAtual = new Date();
    const dataFormatadaBR = dataAtual.toLocaleString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // Para usar o formato de 24 horas
    });

    return (
        <Box
            mt={3}
            sx={{
                backgroundColor: '#1e1e1e',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                padding: '15px',
                borderRadius: '5px',
                margin: '1rem auto',
                textAlign: 'center',
                transition: 'all 0.3s ease',
            }}
        >
            <Typography
                variant="h4"
                gutterBottom
                sx={{
                    color: "#a9a9a9",
                    fontWeight: 'bold',
                    mb: 3
                }}
            >
                Comprovante de Compra
            </Typography>
            <Box
                sx={{
                    backgroundColor: '#181818',
                    padding: '20px',
                    borderRadius: '12px',
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    textAlign: 'left',
                    '&:hover': {
                        backgroundColor: '#0e0e0e',
                    },
                }}
            >
                <Tooltip title="Código da solicitação">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', fontSize: 18, color: "#555" }}>
                            <ReceiptLongIcon sx={{ mr: 1, color: '#7db362' }} />
                            <strong>Identificação:</strong>
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: 18, color: "#777", pl: 4 }}>
                            {resumeId}
                        </Typography>
                    </Box>
                </Tooltip>

                <Tooltip title="Nome do cliente">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', fontSize: 18, color: "#555" }}>
                            <PersonIcon sx={{ mr: 1, color: '#7db362' }} />
                            <strong>Nome</strong>
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: 18, color: "#777", pl: 4 }}>
                            {nome}
                        </Typography>
                    </Box>
                </Tooltip>

                <Tooltip title="Endereço de email">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', fontSize: 18, color: "#555" }}>
                            <EmailIcon sx={{ mr: 1, color: '#7db362' }} />
                            <strong>E-mail</strong>
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: 18, color: "#777", pl: 4 }}>
                            {email}
                        </Typography>
                    </Box>
                </Tooltip>

                <Tooltip title="Quantidade adquirida">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', fontSize: 18, color: "#555" }}>
                            <ShoppingCartIcon sx={{ mr: 1, color: '#7db362' }} />
                            <strong>Quantidade</strong>
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: 18, color: "#777", pl: 4 }}>
                            {quantidade}
                        </Typography>
                    </Box>
                </Tooltip>

                <Tooltip title="Valor total">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', fontSize: 18, color: "#555" }}>
                            <AttachMoneyIcon sx={{ mr: 1, color: '#7db362' }} />
                            <strong>Valor</strong>
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: 18, color: "#777", pl: 4 }}>
                            R$ {valor}
                        </Typography>
                    </Box>
                </Tooltip>

                <Tooltip title="Data da compra">
                    <Box>
                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', fontSize: 18, color: "#555" }}>
                            <EventIcon sx={{ mr: 1, color: '#7db362' }} />
                            <strong>Data do pagamento</strong>
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: 18, color: "#777", pl: 4 }}>
                            {dataFormatadaBR}
                        </Typography>
                    </Box>
                </Tooltip>
            </Box>
        </Box>
    );
};

export default Resume;
