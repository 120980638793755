import { Box, Button, Dialog, DialogContent, DialogTitle, TextField, TextareaAutosize, Typography, useTheme } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { tokens } from "../theme";
import { useEffect, useState } from "react";

import { HOST_SINGED } from "../config/hosts";

const ModalFeedback = ({isOpen, setOpen, keyProposal}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [textJustify, setTextJustify] = useState('');

    return(
        <Dialog open={isOpen}>
            <ToastContainer />
            <DialogTitle>
            <Typography
                variant="h3"
                color={colors.greenAccent[400]}
            >
                Feedback
            </Typography>
            
            </DialogTitle>

            <DialogContent>
                <Box display="flex" flexDirection="column" gap={3}>
                    <Typography
                        variant="h5"
                    >
                        Informe o motivo de estar rejeitando a proposta para que o nosso time possa pensar em soluções mais adequadas para você
                    </Typography>
        
                    <TextField 
                        type="text"
                        color="secondary"
                        value={textJustify}
                        onChange={(e) => setTextJustify(e.target.value)}
                        label="Informe o motivo de rejeição..." 
                    />
                        
                    <Box display="flex" alignContent="center" justifyContent="space-around">
                        <Button onClick={() => {
                             fetch(`${HOST_SINGED}/reduto/proposal/reject/` + keyProposal, {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Accept: "*/*",
                                    },
                                    body: JSON.stringify({
                                        justify: textJustify
                                    })
                                  })
                                  .then((res) => {
                                    if (res.status === 200) {
                                      toast.info("Proposta rejeitada!", { theme: "dark" });
                                      window.location.reload();
                                    }
                                  })
                                }} 
                            variant="contained" color="secondary">
                            Enviar feedback
                        </Button>
                        <Button onClick={() => setOpen(!isOpen)} variant="contained" color="secondary">
                            Cancelar
                        </Button>
                    </Box>
                </Box>
            </DialogContent>

        </Dialog>
    ); 
}

export default ModalFeedback;
