/* eslint-disable array-callback-return */
import React from "react";
import RedSimulation from "../redsimulation";

const Red25Sem = () => {
  // props
  let uri = 'redsafra/';
  let type = "RED25 - Sem";
  let title = "Semestral";

  return (
    <RedSimulation title={title} simulationUri={uri} type_simulation={type} />
  );
};

export default Red25Sem;
