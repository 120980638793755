import { QrCodePix } from "qrcode-pix";
import React, { useEffect, useState } from 'react';

const PixQRCode = ({ value, extrato }) => {
    const [qrCode, setQrCode] = useState('');
    const [rawPix, setRawPix] = useState('');

    useEffect(() => {
        async function handleQrCode(){ 
            const qrCodePix = QrCodePix({
                version: '01',
                key: 'financeiro@redutocapital.com.br',
                name: 'REDUTO CAPITAL SERVICOS EM TI LTDA',
                city: 'SAO PAULO',
                transactionId: 'redutorecebimentos',
                message: `${extrato}`,
                value: value,
            })

            const rawPixStr = qrCodePix.payload()
            const qrCodeBase64 = await qrCodePix.base64()

            setRawPix(rawPixStr);
            setQrCode(qrCodeBase64)
        }
        handleQrCode();
    })
    

    return (

        
        <div>
            <div
                style={{ marginTop: 20 }}
            >
                <img src={qrCode} alt={'QR Code PIX'} />
            </div>
        </div>
    );
};


export default PixQRCode;