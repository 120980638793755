import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import HandlerDataInfos from "../HandleDataInfo";

const DataInfo = ({
  cpf,
  tokenSerasa,
  idConsult,
  algorithm,
  userId,
  isClient,
  clientId,
  amount,
  installments,
  simulationUri,
  title,
  noProposal
}) => {
  const [count, setCount] = useState(2);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    // Limpar o timer quando o componente for desmontado
    return () => clearInterval(timer);
  }, []);

  return (
    <Box>
      <>
        <HandlerDataInfos
          cpfcnpj={cpf}
          idConsult={idConsult}
          tokenBrain={tokenSerasa}
          algorithm={algorithm}
          isClient={isClient}
          amount={amount}
          clientId={clientId}
          userId={userId}
          installments={installments}
          simulationUri={simulationUri}
          title={title}
          noProposal={noProposal}
        />
      </>
    </Box>
  );
};

export default DataInfo