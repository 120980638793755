import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Header from "../../components/Header";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
  Tooltip,
  Typography
} from "@mui/material";

import Paper from "@mui/material/Paper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookiesLogin } from "../../config/cookies";
import ModalFeedback from "../../components/ModalFeedback";
import { greenColor } from "../../config/colors";
import { HOST_SINGED } from "../../config/hosts";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export const ProposalsUser = () => {
  const [proposals, setProposals] = useState([]);
  const { cookies } = useCookiesLogin();
  const { email } = cookies.BRAUM_TOKEN;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keyProposal, setKeyProposal] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${HOST_SINGED}/reduto/proposal/client`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      body: JSON.stringify({
        email: email
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res;
        }
      })
      .then((response) => response.json())
      .then((data) => {
        setProposals(data);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    document.title = 'Propostas';
  }, []);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };

  return (
    <>
      <Box m="20px">
        <ToastContainer />
        <Header
          title="Propostas"
          subtitle="Área destinadas para propostas aberta aos nossos clientes."
        />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="customized table">
            <TableHead>
              <TableRow >
                <TableCell align="left">Nome</TableCell>
                <TableCell align="left">Revenda</TableCell>
                <TableCell align="left">Simulação</TableCell>
                <TableCell align="left">Valor Total</TableCell>
                <TableCell align="left">Valor Parcela</TableCell>
                <TableCell align="left">Quantidade Parcelas</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Score</TableCell>
                <TableCell align="left">Progresso</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {proposals.map((row) => (
                <TableRow
                  key={row.pk}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.fields.name}
                  </TableCell>
                  <TableCell align="left">{row.fields.business_name}</TableCell>
                  <TableCell align="left">
                    {row.fields.type_simulation.toUpperCase()}
                  </TableCell>
                  <TableCell align="left">
                    {formatCurrency(parseFloat(row.fields.amount_total))}
                  </TableCell>
                  <TableCell align="left">
                    {formatCurrency(parseFloat(row.fields.value_installments))}
                  </TableCell>
                  <TableCell align="left">
                    {row.fields.installments}
                  </TableCell>
                  <Tooltip title={<Typography variant="h5">{row.fields.description}</Typography>}>
                    <TableCell
                      align="left"
                      sx={{ color: row.fields.status === "CREATED" ? "#fff28b" : row.fields.status === "APPROVED" ? "#83d84d" : "#ff5050", fontWeight: 900 }}
                    >
                      {
                        row.fields.status === "CREATED"
                          ? "ESPERANDO APROVAÇÃO"
                          : row.fields.status === "APPROVED" ? "APROVADO" : "REJEITADO"
                      }
                    </TableCell>
                  </Tooltip>
                  <TableCell align="left">{row.fields.score}</TableCell>
                  {row.fields.status === "CREATED" ? (
                    <TableCell align="left">

                      <Button onClick={() => {
                        fetch(`${HOST_SINGED}/reduto/proposal/approve/` + row.pk, {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Accept: "*/*",
                          }
                        })
                          .then((res) => {
                            if (res.status === 200) {
                              toast.success("Proposta aprovada!", { theme: "dark" });
                              window.location.reload();
                            }
                          })
                      }} color="secondary" variant="outlined">
                        APROVAR
                      </Button>

                      <Button

                        onClick={() => {
                          setIsModalOpen(!isModalOpen);
                          setKeyProposal(row.pk);
                        }}
                        sx={{ color: "#ff5050" }} variant="outlined">
                        REJEITAR
                      </Button>
                    </TableCell>
                  ) : (
                    <TableCell align="left">
                      <Typography variant="body2">
                        Proposta Finalizada
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <ModalFeedback isOpen={isModalOpen} setOpen={setIsModalOpen} keyProposal={keyProposal} />
        <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
          <CircularProgress color="inherit" sx={{ color: greenColor }} />
        </Backdrop>
      </Box>
    </>
  );
};
