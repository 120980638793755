import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useCookiesLogin } from "../../config/cookies";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  Tooltip,
  Typography
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ModalFeedback from "../../components/ModalFeedback";
import { compareAsc } from "date-fns";
import { greenColor } from "../../config/colors";
import { HOST_SINGED } from "../../config/hosts";
import ModalFile from "../../components/ModelFile";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export const Proposals = () => {
  const { cookies } = useCookiesLogin();
  const response = cookies.BRAUM_TOKEN || {};
  const role = response.role;
  let proposal_url;

  const [proposals, setProposals] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [consulta, setConsulta] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keyProposal, setKeyProposal] = useState(0);

  const [isModalFileOpen, setIsModalFileOpen] = useState(false);

  const [businessName, setBusinessName] = useState("");
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    setIsLoading(true);

    switch (role) {
      case "admin":
        proposal_url = `${HOST_SINGED}/reduto/proposal/all`;
        break;
      case "consultant":
        const business_id = response.bussines_id;
        proposal_url =
          `${HOST_SINGED}/reduto/proposal/business/` + business_id;
        break;
      default:
        console.log("INVALID");
    }

    fetch(proposal_url, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        setProposals(data);
        setIsLoading(false);
      });
  }, [sortBy, sortOrder, response]);

  useEffect(() => {
    document.title = "Propostas";
  }, []);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };

  const handleApproveProposal = (proposalId) => {
    fetch(`${HOST_SINGED}/reduto/proposal/approve/` + proposalId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    })
      .then(async (res) => {
        if (res.ok) {
          toast.success("Proposta aprovada!", {theme: "dark"});
                    window.location.reload();
        }
      })
  };

  const renderTableRows = (data) => {
    return data.map((row) => (
      <>
        <ToastContainer />
        <TableRow
          key={row.pk}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {row.fields.name.toUpperCase()}
          </TableCell>
          <TableCell align="left">{row.fields.business_name.toUpperCase()}</TableCell>
          <TableCell align="left">
            {row.fields.type_simulation.toUpperCase()}
          </TableCell>
          <TableCell align="left">
            {formatCurrency(parseFloat(row.fields.amount_total))}
          </TableCell>
          <TableCell align="left">
            {formatCurrency(parseFloat(row.fields.value_installments))}
          </TableCell>
          <TableCell align="left">{row.fields.installments}</TableCell>
          <TableCell align="left">
            {new Date(row.fields.created_at).toLocaleDateString()}
          </TableCell>
          <Tooltip title={<Typography variant="h5">{row.fields.description}</Typography>}>
            <TableCell
              align="left"
              sx={{
                color:
                  row.fields.status === "CREATED"
                    ? "#fff28b"
                    : row.fields.status === "REJECTED"
                      ? "#ff5050"
                      : "#83d84d",
                fontWeight: 900,
              }}
            >
              {row.fields.status === "CREATED"
                ? "NOVA"
                : row.fields.status === "APPROVED"
                  ? "APROVADA"
                  : row.fields.status === "REJECTED"
                    ? "REJEITADA"
                    : "FINALIZADA"
              }
            </TableCell>
          </Tooltip>
          <TableCell align="left">{row.fields.score ? row.fields.score : "-"}</TableCell>
          <TableCell align="left">
            {isNaN(parseFloat(row.fields.event_probability).toFixed(3)) ? row.fields.event_probability : (parseFloat(row.fields.event_probability) * 100).toFixed(3) + "%"}
          </TableCell>
          {row.fields.status === "CREATED" ? (
            <TableCell align="left">

              <Button
                onClick={() => handleApproveProposal(row.pk)}
                color="secondary"
                variant="outlined"
              >
                APROVAR
              </Button>

              <Button

                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  setKeyProposal(row.pk);
                }}
                sx={{ color: "#ff5050" }} variant="outlined">
                REJEITAR
              </Button>
            </TableCell>
          ) : (
            row.fields.status === "APPROVED" ? (
              <TableCell align="left">
                <Button
                  onClick={() => {
                    setIsModalFileOpen(!isModalFileOpen);
                    setKeyProposal(row.pk);
                    setClientName(row.fields.name);
                    setBusinessName(row.fields.business_name);
                  }}
                  color="secondary"
                  variant="outlined"
                >
                  Adicionar arquivo
                </Button>
              </ TableCell>
            ) : (
              <TableCell align="left">
                <Typography variant="body2">
                  Proposta Finalizada
                </Typography>
              </TableCell>
            )
          )}
          <ModalFeedback isOpen={isModalOpen} setOpen={setIsModalOpen} keyProposal={keyProposal} />
          <ModalFile isOpen={isModalFileOpen} setOpen={setIsModalFileOpen} keyProposal={keyProposal} client_name={clientName} business_name={businessName}/>
        </TableRow>
      </>
    ));
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
  };

  const getSortIcon = (field) => {
    if (sortBy === field) {
      return sortOrder === "asc" ? (
        <ArrowUpwardIcon color="white" />
      ) : (
        <ArrowDownwardIcon color="white" />
      );
    }
    return null;
  };

  const limparFiltro = () => {
    setConsulta("");
    setSortBy("");
  };

  const propostasFiltradas = proposals.filter((proposta) =>
    proposta.fields.name.toLowerCase().includes(consulta.toLowerCase())
  );

  const [filteredAndSortedProposals, setFilteredAndSortedProposals] = useState([]);

  useEffect(() => {
    const sortProposals = () => {
      const sorted = [...propostasFiltradas].sort((a, b) => {
        if (sortBy === "status") {
          return sortOrder === "asc"
            ? a.fields.status.localeCompare(b.fields.status)
            : b.fields.status.localeCompare(a.fields.status);
        } else if (sortBy === "value_installments") {
          return sortOrder === "asc"
            ? parseFloat(a.fields.value_installments) - parseFloat(b.fields.value_installments)
            : parseFloat(b.fields.value_installments) - parseFloat(a.fields.value_installments);
        } else if (sortBy === "amount_total") {
          return sortOrder === "asc"
            ? parseFloat(a.fields.amount_total) - parseFloat(b.fields.amount_total)
            : parseFloat(b.fields.amount_total) - parseFloat(a.fields.amount_total);
        } else if (sortBy === "installments") {
          return sortOrder === "asc"
            ? parseInt(a.fields.installments) - parseInt(b.fields.installments)
            : parseInt(b.fields.installments) - parseInt(a.fields.installments);
        } else if (sortBy === "score") {
          return sortOrder === "asc"
            ? parseFloat(a.fields.score) - parseFloat(b.fields.score)
            : parseFloat(b.fields.score) - parseFloat(a.fields.score);
        } else if (sortBy === "event_probability") {
          return sortOrder === "asc"
            ? parseFloat(a.fields.event_probability) - parseFloat(b.fields.event_probability)
            : parseFloat(b.fields.event_probability) - parseFloat(a.fields.event_probability);
        } else if (sortBy === "name") {
          return sortOrder === "asc"
            ? a.fields.name.localeCompare(b.fields.name)
            : b.fields.name.localeCompare(a.fields.name);
        } else if (sortBy === "business_name") {
          return sortOrder === "asc"
            ? a.fields.business_name.localeCompare(b.fields.business_name)
            : b.fields.business_name.localeCompare(a.fields.business_name);
        } else if (sortBy === "created_at") {
          const dateA = new Date(a.fields.created_at);
          const dateB = new Date(b.fields.created_at);
          return sortOrder === "asc" ? compareAsc(dateA, dateB) : compareAsc(dateB, dateA);
        }
        return 0;

      });
      setFilteredAndSortedProposals(sorted);
    };

    sortProposals();
  }, [propostasFiltradas, sortBy, sortOrder]);

  return (
    <>
      <Box m="20px">
        <Header
          title="Propostas"
          subtitle="Área destinada para propostas abertas aos nossos clientes."
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="Pesquisar por nome"
            variant="outlined"
            fullWidth
            value={consulta}
            onChange={(e) => setConsulta(e.target.value)}
          />
          <Button
            variant="outlined"
            color="secondary"
            onClick={limparFiltro}
            style={{ marginLeft: "10px" }}
          >
            Limpar Filtro
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="tabela simples">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("name")}
                    startIcon={getSortIcon("name")}
                    sx={{ color: "white" }}
                  >
                    Nome
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("business_name")}
                    startIcon={getSortIcon("business_name")}
                    sx={{ color: "white" }}
                  >
                    Revenda
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("type_simulation")}
                    startIcon={getSortIcon("type_simulation")}
                    sx={{ color: "white" }}
                  >
                    Simulação
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("amount_total")}
                    startIcon={getSortIcon("amount_total")}
                    sx={{ color: "white" }}
                  >
                    Valor total
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("value_installments")}
                    startIcon={getSortIcon("value_installments")}
                    sx={{ color: "white" }}
                  >
                    Valor Parcela
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("installments")}
                    startIcon={getSortIcon("installments")}
                    sx={{ color: "white" }}
                  >
                    Quantidade Parcelas
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("created_at")}
                    startIcon={getSortIcon("created_at")}
                    sx={{ color: "white" }}
                  >
                    Data da Proposta
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("status")}
                    startIcon={getSortIcon("status")}
                    sx={{ color: "white" }}
                  >
                    Status
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("score")}
                    startIcon={getSortIcon("score")}
                    sx={{ color: "white" }}
                  >
                    Score
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("event_probability")}
                    startIcon={getSortIcon("event_probability")}
                    sx={{ color: "white" }}
                  >
                    Porcentagem de Risco
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    sx={{ color: "white" }}
                  >
                    Progresso
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableRows(filteredAndSortedProposals)}</TableBody>
          </Table>
        </TableContainer>

        <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
          <CircularProgress color="inherit" sx={{ color: greenColor }} />
        </Backdrop>
      </Box>
    </>
  );
};
